
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import DropdownEvaluation from "@/components/dropdown/DropdownEvaluation.vue";

export default defineComponent({
  name: "manage-times",
  components: {
    DropdownEvaluation,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = [
      {
        userid: "56037",
        name: {
          last: "Smith",
          first: "Emma",
        },
        assignment: "0",
        salaryType: "EUR",
        factor: "2",
        salary: "1000",
        total: "2000",
        holiday: "1",
        diets: "1-2-1",
        qst: "2",
        ahv: "2",
        bvg: "3",
      },
      {
        userid: "05822",
        name: {
          last: "Bean",
          first: "Sean",
        },
        assignment: "0",
        salaryType: "EUR",
        factor: "0",
        salary: "500",
        total: "500",
        holiday: "1",
        diets: "1-0-0",
        qst: "2",
        ahv: "1",
        bvg: "3",
      },
      {
        userid: "4472",
        name: {
          last: "Cox",
          first: "Brian",
        },
        assignment: "0",
        salaryType: "CHF",
        factor: "2",
        salary: "1000",
        total: "2000",
        holiday: "1",
        diets: "1-2-1",
        qst: "2",
        ahv: "2",
        bvg: "7",
      },
      {
        userid: "00347",
        name: {
          last: "Collins",
          first: "Mikaela",
        },
        assignment: "0",
        salaryType: "EUR",
        factor: "2",
        salary: "1000",
        total: "2000",
        holiday: "1",
        diets: "1-2-1",
        qst: "2",
        ahv: "2",
        bvg: "3",
      },
      {
        userid: "59486",
        name: {
          last: "Mitcham",
          first: "Francis",
        },
        assignment: "0",
        salaryType: "EUR",
        factor: "2",
        salary: "750",
        total: "1500",
        holiday: "1",
        diets: "0-0-0",
        qst: "2",
        ahv: "2",
        bvg: "3",
      },
    ];

    onMounted(() => {
      setCurrentPageTitle("dataManagement.evaluation");
    });

    const searchItems = () => {
      //TODO
    };

    return {
      list,
    };

  },
});
